import * as React from "react";
import { Heading, Text, Image, Flex, Box } from "rebass";
import Page from "../components/Page";
import IndexLayout from "../layouts";
import { Helmet } from "react-helmet";
import SiteFooter from "../components/SiteFooter";

import * as styles from "./Story.module.css";
import HeroLayoutEmpty from "../components/HeroLayoutEmpty";

import HeroGridCard from "../components/HeroGridCard";
import HeroGrid from "../components/HeroGrid";

import devinProfile from "../assets/images/devin.png";
import devinImage1 from "../assets/images/devin-image-1.jpg";
import storyIcon1 from "../assets/images/story-icon-1.png";
import storyIcon2 from "../assets/images/story-icon-2.png";
import storyIcon3 from "../assets/images/story-icon-3.png";
import storyIcon4 from "../assets/images/story-icon-4.png";

const DevinStoryPage = ({ data }: any) => {
  return (
    <IndexLayout>
      <Page>
        <HeroGrid
          style={{ position: "relative", overflow: "hidden", rowGap: "0" }}
        >
          <Helmet>
            <title>Skodel - Devin's Story</title>
            <meta property="og:title" content="Skodel - Devin's Story" />
            <meta
              property="og:description"
              content="Read Devin's story, how Skodel supports Learnlife and why he is passionate about this area"
            />
          </Helmet>
          <HeroGridCard
            className={styles.storyDevinContainer}
            color="white"
            style={{ maxWidth: "unset" }}
          >
            <Box className={styles.heroStoryDevinStripes}>
              <Box className={styles.heroStoryDevinStripesInner}>
                <Box className={styles.heroStoryDevinStripe1} />
                <Box className={styles.heroStoryDevinStripe2} />
                <Box className={styles.heroStoryDevinStripe3} />
              </Box>
            </Box>
            <HeroLayoutEmpty className={styles.storyDevinWrapper}>
              <Flex justifyContent="center">
                <Box className={styles.storyDevinContent}>
                  <Box className={styles.storyDevinImage}>
                    <Image src={devinProfile} alt="A photo of Devin Carberry" />
                  </Box>
                  <Heading className={styles.storyDevinName}>
                    Devin Carberry
                  </Heading>
                  <Heading className={styles.storyDevinOccupation}>
                    Director of Learning Programs
                  </Heading>
                  <Heading className={styles.storyDevinOccupationBig}>
                    Key Takeaway from Skodel: Identifying wellbeing issues as
                    they arise
                  </Heading>
                  <Text className={styles.storyDevinMessage}>
                    “Learning happens best when we feel good about ourselves,
                    about the people around us and about what we are learning.
                    When there are emotional blockers in the way of any of these
                    three elements, then learning is stymied. Skodel gives us
                    the opportunity to assess the emotional well-being of our
                    community, and to respond to blockages as they arise.”
                  </Text>
                  <Box className={styles.storySparator}>
                    <Box className={styles.storySparatorBullet}></Box>
                    <Box className={styles.storySparatorBullet}></Box>
                    <Box className={styles.storySparatorBullet}></Box>
                    <Box className={styles.storySparatorBullet}></Box>
                  </Box>
                  <Box className={styles.storyGroup}>
                    <Box className={styles.storyGroupImage}>
                      <Image src={storyIcon1} alt="" />
                    </Box>
                    <Box className={styles.storyGroupText}>
                      <Heading className={styles.storyGroupTitle}>
                        What inspired you to become a teacher?
                      </Heading>
                      <Text className={styles.storyGroupDescription}>
                        I went to traditional public schools. I hated it.
                        Everything was the same. Memorize, regurgitate, repeat.
                        My last year of high school, I signed up for a
                        psychology class. The teacher had run an alternative
                        school for a decade, and was still conducting his
                        classroom as such. The learners drove the topics and
                        discussions. The class felt like a community--we
                        supported each other, democratically resolved conflicts
                        or made important decisions, and we use our lives as
                        fodder for conversation. The teacher also related to us
                        as human beings and showed us unconditional positive
                        regard. Up until that point, I did not know any of this
                        was possible. Inspired by my experience, I started
                        university the next year with the desire to know more.
                        As part of my coursework, I studied progressive
                        education and visited a half dozen progressive schools
                        in New York City. At the same time, I had a work-study
                        job as an assistant teacher in a public school in
                        Brooklyn. It looked and felt like a prison. Contrasting
                        this school with the progressive schools I was visiting,
                        my purpose in life became clear: education had to
                        change.
                      </Text>
                    </Box>
                  </Box>
                  <Box className={styles.storyGroup}>
                    <Box className={styles.storyGroupImage}>
                      <Image src={storyIcon2} alt="" />
                    </Box>
                    <Box className={styles.storyGroupText}>
                      <Heading className={styles.storyGroupTitle}>
                        Describe one moment that made you realise how special it
                        is to be a teacher.
                      </Heading>
                      <Text className={styles.storyGroupDescription}>
                        Sometimes teachers have an immediate impact on the lives
                        of our students. Sometimes we have to play the
                        long-game. At one of the high schools where I worked, I
                        requested to move up with my students from their first
                        year until their graduation. All of my students would be
                        the first in their families to go to university in a
                        school district where fewer than 15% of students go to
                        university. Many of my students doubted if going to
                        university would ever be a reality for them. One learner
                        in particular had what felt like fortnightly crises.
                        “I’m giving up, Devin. I can’t do this.,” she’d say. The
                        pressure to drop out and start working, the negative
                        self-talk telling her she was not smart enough, and the
                        fact that no one in her community but her teachers had
                        been to university, made the four year journey a rough
                        one. This is why victory was so sweet when, after four
                        years of saying she was going to give up, she gave up on
                        giving up. She graduated from high school, with her
                        entire extended family there--vacillating between tears
                        and cheers--to celebrate her graduation and acceptance
                        to university.
                      </Text>
                    </Box>
                  </Box>
                  <Box className={styles.storyGroup}>
                    <Box className={styles.storyGroupImage}>
                      <Image src={storyIcon4} alt="" />
                    </Box>
                    <Box className={styles.storyGroupText}>
                      <Heading className={styles.storyGroupTitle}>
                        Learnlife is a different model, describe the thinking
                        behind starting it and in particular, the why.
                      </Heading>
                      <Text className={styles.storyGroupDescription}>
                        A realistic look at the global educational landscape,
                        makes it abundantly clear why Learnlife is an
                        imperative. Most schools still look like they did during
                        the First Industrial Revolution. Meanwhile, the Fourth
                        Industrial Revolution has and will continue to bring
                        about such radical changes to our world that schools
                        have to change. Partner this with the staggering
                        statistics about the rise of depression, anxiety, and
                        other mental health issues for youth, clearly we cannot
                        continue with business as usual. The world needs and
                        young people deserve learning communities that provide
                        them with opportunities to explore who they are and what
                        they have to offer, to develop 21st Century skills, to
                        become agents of change, and to learn what they will
                        need in order to thrive throughout their lifetimes.
                      </Text>
                      <Image
                        className={styles.storyImage}
                        src={devinImage1}
                        alt=""
                      />
                    </Box>
                  </Box>
                  <Box className={styles.storyGroup}>
                    <Box className={styles.storyGroupImage}>
                      <Image src={storyIcon3} alt="" />
                    </Box>
                    <Box className={styles.storyGroupText}>
                      <Heading className={styles.storyGroupTitle}>
                        Why did you decide to bring Skodel in to Learnlife?
                      </Heading>
                      <Text className={styles.storyGroupDescription}>
                        Learning happens best when we feel good about ourselves,
                        about the people around us and about what we are
                        learning. When there are emotional blockers in the way
                        of any of these three elements, then learning is
                        stymied. Skodel gives us the opportunity to assess the
                        emotional well-being of our community, and to respond to
                        blockages as they arise.
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Flex>
            </HeroLayoutEmpty>
          </HeroGridCard>
          <SiteFooter />
        </HeroGrid>
      </Page>
    </IndexLayout>
  );
};

export default DevinStoryPage;
